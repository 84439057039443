/* eslint-disable react-hooks/exhaustive-deps */
import { Spinner, useToast } from "@chakra-ui/react";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import SolidButton from "../components/buttons/solidButton";
import Container from "../components/layout/container";
import BottomSection from "../components/sections/bottomSection";
import MiddleSection from "../components/sections/middleSection";
import TopSection from "../components/sections/topSection";
import { constants } from "../constants/constants";
import checkoutState from "../recoil/checkoutState";
import payloadState from "../recoil/payloadState";
import uiState from "../recoil/uiState";
import { sendPaymentRequest } from "../services/payments";
import {
  createCheckoutRequest,
  getCheckoutRequestStatus,
} from "../services/checkout";

function MobileBankingPage() {
  const toast = useToast();
  const navigate = useNavigate();
  const payload = useRecoilValue(payloadState);
  const [checkout, setCheckout] = useRecoilState(checkoutState);
  const [isLoading, setIsLoading] = useState(true);
  const [isCheckingStatus, setIsCheckingStatus] = useState(false);
  const ui = useRecoilValue(uiState);
  const [paymentDetails, setPaymentDetails] = useState(null);

  const retryPayment = async () => {
    try {
      const response = await createCheckoutRequest({
        body: payload.merchant_site_data,
        token: payload.access_token,
      });
      window.open(
        `https://checkout.lipad.io?access_token=${response.access_token}&params=${response.payload}`,
        "_self"
      );
    } catch (error) {}
  };

  async function fetchData() {
    setIsLoading(true);
    try {
      const response = await sendPaymentRequest({
        checkout_request_id: payload.checkout_request_id,
        payment_method_type: "bank-transfer",
        token: payload.access_token,
        checkout: {
          ...checkout,
          country_code: ui.selectedCountry.country_code,
        },
      });
      setCheckout((prevState) => ({
        ...prevState,
        charge_request_id: response.chargeRequestID,
      }));
      if (response.redirect === true) {
        navigate("/process-payment", {
          state: {
            src: response.checkout_redirect_url,
          },
        });
      } else {
        setPaymentDetails(response.bank_info);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch payment details.",
        status: "error",
        duration: constants.TOAST_DURATION,
        position: constants.TOAST_POSITION,
        isClosable: true,
      });
      navigate(-1);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const checkPaymentStatus = async () => {
    setIsCheckingStatus(true);
    try {
      const response = await getCheckoutRequestStatus({
        checkout_request_id: payload.checkout_request_id,
        token: payload.access_token,
      });
      processRedirect(response);
    } catch (error) {
      processRedirect();
    }
    setIsCheckingStatus(false);
  };

  const processRedirect = (data) => {
    if (data?.statusCode === 1) {
      navigate("/payment-successful");
      return;
    } else if ([2, 3, 4, 5, 6].includes(data?.statusCode)) {
      navigate("/payment-pending", {
        state: {
          statusDescription:
            data?.statusDescription ?? "Payment is pending processing.",
        },
      });
      return;
    } else {
      navigate("/payment-failed", {
        state: {
          statusDescription:
            data?.statusDescription ??
            "Something went wrong. Please try again.",
        },
      });
      return;
    }
  };

  return (
    <Container>
      <div>
        <TopSection />
        <MiddleSection />
        <div className="-mt-8 space-y-2 rounded-t-xl bg-white p-4 sm:p-6">
          <div className="relative flex">
            <button onClick={() => retryPayment()}>
              <HiOutlineChevronLeft className="absolute inset-y-0 left-0 h-full w-5 cursor-pointer text-black" />
            </button>
            <h2 className="mx-auto font-medium">{`Pay with ${checkout.payment_method_name}`}</h2>
          </div>
          {isLoading ? (
            <div className="grid h-48 place-items-center">
              <Spinner size="lg" />
            </div>
          ) : (
            <div className="space-y-2 p-2">
              <h2 className="font-medium">Payment Instructions</h2>
              <ol className="ml-4 list-decimal">
                <li>Login to your mobile or internet banking</li>
                <li>Select Transfer to other banks or Mobile Money</li>
                <li>
                  Select <strong>{paymentDetails?.bankName ?? "Bank"}</strong>{" "}
                  as the bank to transfer to
                </li>
                <li>
                  Enter account name{" "}
                  <strong>
                    {paymentDetails?.accountName ?? "Account Name"}
                  </strong>
                </li>
                <li>
                  Enter account number{" "}
                  <strong>
                    {paymentDetails?.accountNumber ?? "Account Number"}
                  </strong>
                </li>
                <li>
                  Enter amount as{" "}
                  <strong>
                    {payload.merchant_site_data.currency_code}{" "}
                    {numeral(payload.merchant_site_data.request_amount).format(
                      "0,0.00"
                    )}
                  </strong>
                </li>
              </ol>
              <SolidButton
                label="Confirm Payment"
                isSubmitting={isCheckingStatus}
                disabled={isCheckingStatus}
                onClick={() => checkPaymentStatus()}
              />
            </div>
          )}
        </div>
        <BottomSection />
      </div>
    </Container>
  );
}

export default MobileBankingPage;
