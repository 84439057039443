import { useRecoilState, useRecoilValue } from "recoil";
import OutlineButton from "../components/buttons/outlineButton";
import SolidButton from "../components/buttons/solidButton";
import Container from "../components/layout/container";
import BottomSection from "../components/sections/bottomSection";
import TopSection from "../components/sections/topSection";
import checkoutState from "../recoil/checkoutState";
import payloadState from "../recoil/payloadState";
import { sendPaymentRequest } from "../services/payments";
import image from "../assets/images/illustrations/hand-holding-phone.svg";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { constants } from "../constants/constants";
import { useState } from "react";

export default function AuthorizePaymentPage() {
  const navigate = useNavigate();
  const [checkout, setCheckout] = useRecoilState(checkoutState);
  const payload = useRecoilValue(payloadState);
  const toast = useToast();
  const [isSubmitting, setisSubmitting] = useState(false);

  return (
    <Container>
      <div>
        <TopSection />
        <div className="p-8 text-sm sm:text-base">
          <div className="flex flex-col items-center gap-1">
            <h2 className="text-primary text-xl font-medium">
              Authorise payment
            </h2>
            <p className="text-center">
              Please ensure you have your phone with you and sufficient balance
              in your account. We will send a prompt to{" "}
              <span className="font-medium">{checkout.payer_msisdn}</span>.
              Enter your PIN to authorize your payment of{" "}
              <span className="font-medium">
                {payload.merchant_site_data.running_amounts
                  .converted_currency ??
                  payload.merchant_site_data.running_amounts
                    .origin_currency_code}{" "}
                {checkout.request_amount}
              </span>{" "}
              to account number{" "}
              <span className="font-medium">
                {payload.merchant_site_data.account_number}.
              </span>
            </p>
            <img className="m-4 h-48 w-48" src={image} alt="" />
            <p>
              Click <strong>Send Prompt</strong> when ready.
            </p>
          </div>
          <div className="mt-8 flex w-full gap-x-2">
            <div className="w-full" onClick={() => navigate(-1)}>
              <OutlineButton label="Back" />
            </div>
            <div className="w-full">
              <SolidButton
                label="Send Prompt"
                isSubmitting={isSubmitting}
                disabled={isSubmitting}
                onClick={() => {
                  setisSubmitting(true);
                  sendPaymentRequest({
                    token: payload.access_token,
                    checkout,
                    checkout_request_id: payload.checkout_request_id,
                    payment_method_type: "mobile-money",
                  })
                    .then((response) => {
                      setCheckout((prevState) => ({
                        ...prevState,
                        charge_request_id: response.chargeRequestID,
                        client_service_code:
                          payload.merchant_site_data.client_service
                            .client_service_code,
                      }));
                      toast({
                        title: "Payment request sent",
                        description:
                          "Your payment request has been sent and is now being processed.",
                        status: "success",
                        duration: constants.TOAST_DURATION,
                        position: constants.TOAST_POSITION,
                        isClosable: true,
                      });
                      setisSubmitting(false);
                      navigate("/process-payment", {
                        state: {
                          payment_method_type: "mobile_money",
                          redirect: null,
                        },
                      });
                    })
                    .catch((error) => {
                      toast({
                        description:
                          "Could not complete request. Please try again later",
                        status: "error",
                        duration: constants.TOAST_DURATION,
                        position: constants.TOAST_POSITION,
                        isClosable: true,
                      });
                      setisSubmitting(false);
                    });
                }}
              />
            </div>
          </div>
        </div>
        <BottomSection />
      </div>
    </Container>
  );
}
