/* eslint-disable react-hooks/exhaustive-deps */
import { useDisclosure, useToast } from "@chakra-ui/react";
import gsap from "gsap";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import socketIOClient from "socket.io-client";
import OutlineButton from "../components/buttons/outlineButton";
import Container from "../components/layout/container";
import MpgsResponse from "../components/mpgsResponse";
import BottomSection from "../components/sections/bottomSection";
import TopSection from "../components/sections/topSection";
import { constants } from "../constants/constants";
import checkoutState from "../recoil/checkoutState";
import payloadState from "../recoil/payloadState";
import { getCheckoutRequestStatus } from "../services/checkout";
import { Spinner } from "@chakra-ui/react";
import LipadSpinner from "../components/animations/lipadSpinner";
import image from "../assets/images/logos/lipad-logo-blue-powered-by.svg";

export default function ProcessingPaymentPage() {
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const toast = useToast();
  const navigate = useNavigate();
  const checkout = useRecoilValue(checkoutState);
  const [payload, setPayload] = useRecoilState(payloadState);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { state } = useLocation();
  const { redirect, timeout } = state;

  var socket;

  useEffect(() => {
    if (!buttonDisabled) {
      gsap
        .timeline()
        .to(".confirm-button", {
          scale: 1.1,
          backgroundColor: "#80859c",
          borderColor: "#80859c",
          borderRadius: "0.375rem",
          duration: 0.25,
        })
        .to(".confirm-button", {
          scale: 1.0,
          backgroundColor: "#263470",
          borderColor: "#263470",
          borderRadius: "0.375rem",
          duration: 0.25,
        });
    }
  }, [buttonDisabled]);

  // Helper function to extract the base domain (e.g., "lipad.io" from "dashboard.lipad.io")
  const getBaseDomain = (url) => {
    try {
      const { hostname } = new URL(url);
      const parts = hostname.split(".");
      return parts.length <= 2
        ? hostname
        : parts.slice(parts.length - 2).join(".");
    } catch (error) {
      console.error("Error parsing URL:", error);
      return url;
    }
  };

  const getApiDomain = () => {
    const origin =
      typeof window !== "undefined" && window.location.origin
        ? window.location.origin
        : "";
    const hostname =
      typeof window !== "undefined" && window.location.hostname
        ? window.location.hostname
        : "";

    if (hostname === "localhost" || origin.includes("localhost")) {
      return "https://api.gateway.lipad.io";
    }

    const regex = /^((sandbox|dev)\.)?checkout\.(.+)$/;
    const match = regex.exec(hostname);

    if (match) {
      const envPrefix = match[2] ? `${match[2]}.` : "";
      const domainPart = match[3]; // e.g., "lipad.io" or "kasapay.com"
      return `https://${envPrefix}api.gateway.${domainPart}`;
    }

    const baseDomain = getBaseDomain(origin);
    return `https://api.gateway.${baseDomain}`;
  };

  const domain = getApiDomain();

  useEffect(() => {
    socket = socketIOClient(`${domain}/api/v1/checkout/request`, {
      extraHeaders: {
        Authorization: `Bearer ${payload.access_token}`,
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      },
    });

    socket.on("connect", (data) => {
      socket.emit("status", {
        checkout_request_id: payload.checkout_request_id,
      });
    });
    // Listen for "checkout-processor" event and logging the payload.
    socket.on("status", (data) => {
      processRedirect(data);
    });
    // Disconnect socket after 30 seconds
    const timer = setTimeout(() => {
      // socket.disconnect();
    }, timeout ?? 30000);
    // Cleanup function for disconnecting socket on unmount.
    return () => {
      socket.disconnect();
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setButtonDisabled(false);
    }, timeout ?? 30000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (redirect != null) {
      window.location.replace(redirect);
    } else {
      // Check status immediately but only if it is not a partial payment
      if (payload.overall_payment_status !== 802) {
        getCheckoutRequestStatus({
          checkout_request_id: payload.checkout_request_id,
          charge_request_id: checkout.charge_request_id,
          token: payload.access_token,
        })
          .then((response) => processRedirect(response))
          .catch((error) =>
            toast({
              description: "Failed to check payment status",
              status: "error",
              duration: constants.TOAST_DURATION,
              position: constants.TOAST_POSITION,
              isClosable: true,
            })
          );
      }
    }
  }, [redirect]);

  const checkPayment = async () => {
    setButtonDisabled(true);
    try {
      const response = await getCheckoutRequestStatus({
        checkout_request_id: payload.checkout_request_id,
        charge_request_id: checkout.charge_request_id,
        token: payload.access_token,
      });
      processRedirect(response);
    } catch (error) {
      toast({
        // title: error.code,
        description: "Failed to check payment status",
        status: "error",
        duration: constants.TOAST_DURATION,
        position: constants.TOAST_POSITION,
        isClosable: true,
      });
    }
    setButtonDisabled(false);
  };

  const processRedirect = (data) => {
    onClose();
    setPayload({
      overall_payment_status: data.overall_payment_status,
      ...payload,
    });
    if (data.event_record.payment_status === 700) {
      if (data.overall_payment_status === 801) {
        navigate("/payment-successful");
      } else if (data.overall_payment_status === 802) {
        navigate("/payment-partial", {
          state: {
            currency_code: data.event_record.currency_code,
            requested_amount: data.request_amount,
            due_amount: data.outstanding_amount,
            paid_amount: data.event_record.amount,
            total_paid_amount: data.amount_paid,
          },
        });
      }
    } else if (data.event_record.payment_status === 701) {
      if (data.overall_payment_status === 803) {
        navigate("/payment-failed", {
          state: {
            message: data.event_record.receiver_narration ?? "",
            overall_payment_status: data.overall_payment_status,
            partial: null,
          },
        });
      } else if (data.overall_payment_status === 802) {
        navigate("/payment-failed", {
          state: {
            message: data.event_record.receiver_narration ?? "",
            overall_payment_status: data.overall_payment_status,
            partial: {
              currency_code: data.event_record.currency_code,
              requested_amount: data.request_amount,
              due_amount: data.outstanding_amount,
              paid_amount: data.event_record.amount,
              total_paid_amount: data.amount_paid,
            },
          },
        });
      }
    } else if (data.event_record.payment_status === 703) {
      navigate("/payment-pending");
    } else if (data.event_record.payment_status === 704) {
      navigate("/payment-jammed");
    }
  };

  return (
    <>
      {/* {redirect && <MpgsResponse redirect={redirect} isOpen={isOpen} onClose={onClose} />} */}
      <Container>
        <div>
          <TopSection />
          <div className="p-8 text-sm sm:text-base">
            <div className="flex flex-col items-center">
              {payload.client_data.checkout_configs?.branding_configs.name ===
              "Lipad" ? (
                <div className="mx-auto my-16 h-auto">
                  <LipadSpinner size={176} image={image} />
                </div>
              ) : (
                <div
                  className={`${!buttonDisabled ? "" : "mx-auto my-16 h-auto"}`}
                >
                  <LipadSpinner
                    size={176}
                    image={
                      payload?.client_data.checkout_configs.branding_configs
                        .logo
                    }
                  />
                </div>
              )}
              <h2 className="text-lg font-medium text-lipad-orange">
                Processing payment
              </h2>
              <span className="px-12 text-center">
                {buttonDisabled ? (
                  <p>Please wait while we confirm your payment status.</p>
                ) : (
                  <p>
                    Please ensure you have paid before clicking{" "}
                    <strong>CONFIRM.</strong>
                  </p>
                )}
              </span>
            </div>
            <div className="mt-12 flex w-full gap-x-2">
              <div className="w-full">
                <Link to="/">
                  <OutlineButton label="Cancel" />
                </Link>
              </div>
              <div className="confirm-button w-full">
                <button
                  disabled={buttonDisabled}
                  onClick={() => checkPayment()}
                  className={`${
                    buttonDisabled
                      ? "cursor-not-allowed border-[#80859c] bg-[#80859c]"
                      : "border-primary bg-primary cursor-pointer"
                  } relative w-full rounded-md border-2 py-3 text-center font-medium tracking-wider text-white`}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
          <BottomSection />
        </div>
      </Container>
    </>
  );
}
