/* eslint-disable no-throw-literal */
import { fetchWrapper } from "../util/helpers";

// Helper function to extract the base domain (e.g., "lipad.io" from "dashboard.lipad.io")
const getBaseDomain = (url) => {
  try {
    const { hostname } = new URL(url);
    const parts = hostname.split(".");
    return parts.length <= 2
      ? hostname
      : parts.slice(parts.length - 2).join(".");
  } catch (error) {
    console.error("Error parsing URL:", error);
    return url;
  }
};

const getApiDomain = () => {
  const origin =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : "";
  const hostname =
    typeof window !== "undefined" && window.location.hostname
      ? window.location.hostname
      : "";

  if (hostname === "localhost" || origin.includes("localhost")) {
    return "https://api.gateway.lipad.io";
  }

  const regex = /^((sandbox|dev)\.)?checkout\.(.+)$/;
  const match = regex.exec(hostname);

  if (match) {
    const envPrefix = match[2] ? `${match[2]}.` : "";
    const domainPart = match[3]; // e.g., "lipad.io" or "kasapay.com"
    return `https://${envPrefix}api.gateway.${domainPart}`;
  }

  const baseDomain = getBaseDomain(origin);
  return `https://api.gateway.${baseDomain}`;
};

const domain = getApiDomain();

export async function createCheckoutInstance({
  accessKey,
  encryptedPayload,
  referrer,
}) {
  const response = await fetch(`${domain}/api/v1/checkout/request`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessKey}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      access_key: accessKey,
      payload: encryptedPayload,
      referrer,
    }),
  });

  const decodedResponse = await response.json();
  if (response.ok) {
    return decodedResponse;
  }
  throw Error({
    code: response.status,
    message: decodedResponse.message ?? "Failed to create a checkout instance",
  });
}

export async function getCheckoutRequestStatus({
  checkout_request_id,
  charge_request_id,
  token,
}) {
  const response = await fetchWrapper({
    // endpoint: `/request/${checkout_request_id}/status?chargeRequestID=${charge_request_id}`,
    endpoint: `/request/${checkout_request_id}/status`,
    method: "GET",
    token,
  });
  const decodedResponse = await response.json();
  if (response.ok) {
    return decodedResponse;
  }
  throw {
    code: decodedResponse.status,
    message: decodedResponse.message ?? "Failed to get checkout request status",
  };
}

export async function createCheckoutRequest({ body, token }) {
  const response = await fetchWrapper({
    endpoint: `/request`,
    method: "POST",
    token,
    body,
  });
  const decodedResponse = await response.json();
  if (response.ok) {
    window.open(
      `${domain}?access_key=${decodedResponse.access_token}&payload=${decodedResponse.payload}`,
      "_self"
    );
  }
  throw Error({
    code: response.status,
    message: decodedResponse.message ?? "Failed to create checkout request",
  });
}

export async function getCheckoutStatus({
  checkout_request_id,
  currency_code,
  token,
}) {
  const response = await fetchWrapper({
    endpoint: `/${checkout_request_id}/countries?currency_code=${currency_code}`,
    method: "GET",
    token,
  });
  const decodedResponse = await response.json();
  if (response.ok) return decodedResponse;
  throw Error({
    code: response.status,
    message: decodedResponse.message ?? "Failed to get checkout status",
  });
}

export async function getIPAddress() {
  const response = await fetch("https://geolocation-db.com/json/");
  const decodedResponse = await response.json();
  if (response.ok) return decodedResponse.IPv4;
  throw Error({
    code: response.status,
    message: decodedResponse.message ?? "Failed to get IP Address",
  });
}

export async function encryptPayload(payload) {
  const response = await fetchWrapper({
    endpoint: "/request/encrypt/test",
    method: "POST",
    body: payload,
  });
  const decodedResponse = await response.json();
  if (response.ok) return decodedResponse;
  throw Error({
    code: response.status,
    message: decodedResponse.message ?? "Failed to encrypt payload",
  });
}
