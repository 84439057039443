/* eslint-disable no-undef */
import { Formik } from "formik";
import moment from "moment";
import React from "react";
import * as Yup from "yup";
import SolidButton from "../components/buttons/solidButton";
import InputField from "../components/input/inputField";
import useScript from "../hooks/useScript";
import { encryptPayload } from "../services/checkout";
import { prepareParameters } from "../util/helpers";
import { encryptData } from "../util/encryption";

export default function TestPage() {
  // useScript("https://uat.checkout-api.lipad.io/lipad-checkout-library.js");
  const initialValues = {
    msisdn: "",
    account_number: "CK",
    country_code: "KEN",
    currency_code: "KES",
    client_code: "NIP-MM11MH0",
    customer_email: "",
    customer_first_name: "",
    customer_last_name: "",
    due_date: moment().add(30, "m").toISOString(),
    // preferred_payment_option_code: "1",
    merchant_transaction_id: Date.now(),
    request_amount: "",
    invoice_number: "",
    request_description: "Test Transaction",
    success_redirect_url: "https://demo.lipad.io/payment-success",
    fail_redirect_url: "https://demo.lipad.io/payment-failed",
    callback_url: "https://demo.lipad.io/api/payment-notification",
    language_code: "en",
    service_code: "NIPCHE423",
    IVKey: "9QrvWlIDk1hycyPzJ54aF7YcaGDNV0",
    consumer_secret: "TocQq1piLV2DVv9SVcCeZTZD06tUSJ",
    access_key: "sjuETrhA8puU5P1nYqnsbgA2RGSgVl",
    //
    host_domain: "https://nips.lipad.io",
  };
  const validationSchema = Yup.object({
    msisdn: Yup.string().required("Required"),
    account_number: Yup.string().required("Required"),
    country_code: Yup.string().required("Required"),
    currency_code: Yup.string().required("Required"),
    client_code: Yup.string().required("Required"),
    customer_email: Yup.string().required("Required"),
    customer_first_name: Yup.string().required("Required"),
    customer_last_name: Yup.string().required("Required"),
    due_date: Yup.string().required("Required"),
    // preferred_payment_option_code: Yup.string().required("Required"),
    merchant_transaction_id: Yup.string().required("Required"),
    request_amount: Yup.number().required("Required"),
    invoice_number: Yup.string().required("Required"),
    request_description: Yup.string().required("Required"),
    success_redirect_url: Yup.string().required("Required"),
    fail_redirect_url: Yup.string().required("Required"),
    callback_url: Yup.string().required("Required"),
    language_code: Yup.string().required("Required"),
    service_code: Yup.string().required("Required"),
    IVKey: Yup.string().required("Required"),
    consumer_secret: Yup.string().required("Required"),
    access_key: Yup.string().required("Required"),
    host_domain: Yup.string().required("Required"),
  });
  const onSubmit = async (values, { setSubmiting }) => {
    // const card = {
    //   expiry: {
    //     month: "05",
    //     year: "27",
    //   },
    //   name_on_card: "KELVIN KIPKORIR",
    //   number: "4478150085584074",
    //   securityCode: "123",
    // };
    // console.log(encryptData(card));
    // return;
    const result = await encryptPayload(values);

    window.open(
      `${values.host_domain}?${prepareParameters({
        access_key: values.access_key,
        payload: result.encrypted_data,
      })}`,
      "_self"
    );
    // await Lipad.makePayment({
    //   payload: result.encrypted_data,
    //   access_key: values.access_key,
    // });
  };
  return (
    <div className="grid h-full min-h-screen place-items-center ">
      <div className="m-8 w-full max-w-xl space-y-4 rounded-xl border p-8 shadow-2xl">
        <h2 className="text-primary text-2xl font-medium">
          Simulate Checkout Request
        </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit} className="space-y-4">
              <InputField name="msisdn" type="tel" label="MSISDN" />
              <InputField
                name="account_number"
                type="text"
                label="Account Number"
              />
              <InputField
                name="country_code"
                type="text"
                label="Country Code"
              />
              <InputField
                name="currency_code"
                type="text"
                label="Currency Code"
              />
              <InputField name="client_code" type="text" label="Client Code" />
              <InputField
                name="customer_email"
                type="email"
                label="Customer Email"
              />
              <InputField
                name="customer_first_name"
                type="text"
                label="Customer First Name"
              />
              <InputField
                name="customer_last_name"
                type="text"
                label="Customer Last Name"
              />
              <InputField name="due_date" type="text" label="Due Date" />
              {/* <InputField
                name="preferred_payment_option_code"
                type="number"
                label="Preffered Payment Option Code"
              /> */}
              <InputField
                name="merchant_transaction_id"
                type="text"
                label="Merchant Transaction ID"
              />
              <InputField
                name="request_amount"
                type="number"
                label="Request Amount"
              />
              <InputField
                name="invoice_number"
                type="text"
                label="Invoice Number"
              />
              <InputField
                name="request_description"
                type="text"
                label="Request Description"
              />
              <InputField
                name="success_redirect_url"
                type="text"
                label="Success Redirect URL"
              />
              <InputField
                name="fail_redirect_url"
                type="text"
                label="Fail Redirect URL"
              />
              <InputField
                name="callback_url"
                type="text"
                label="Callback URL"
              />
              <InputField
                name="language_code"
                type="text"
                label="Language Code"
              />
              <InputField
                name="service_code"
                type="text"
                label="Service Code"
              />
              <InputField name="access_key" type="text" label="Access Key" />
              <InputField name="IVKey" type="text" label="IV Key" />
              <InputField
                name="consumer_secret"
                type="text"
                label="Consumer Secret"
              />
              <InputField name="host_domain" type="text" label="Host Domain" />
              <SolidButton
                type="submit"
                label="Submit"
                isSubmitting={props.isSubmitting}
              />
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
