import { Buffer } from "buffer";
import { countryCodes } from "../data/countryCodes";
import { AES, enc, SHA256, mode } from "crypto-js";

export const prepareParameters = (params) => {
  const preparedParameters = [];
  for (var key in params) {
    if (params.hasOwnProperty(key)) {
      preparedParameters.push(`${key}=${params[key]}`);
    }
  }
  return preparedParameters.join("&");
};

export const decodeBase64 = (encodedData) => {
  let buffer = Buffer.from(encodedData, "base64");
  let data = JSON.parse(buffer.toString("utf8"));
  return data;
};

export const filteredPaymentMethods = ({ payload, type }) => {
  return payload.client_data.payment_methods.filter(
    (e) => e.payment_method_type === type
  );
};

export function convertISO3toISO2(ISO2) {
  return countryCodes.find((e) => e.ISO3 === ISO2).ISO2;
}
// Helper function to extract the base domain (e.g., "lipad.io" from "dashboard.lipad.io")
const getBaseDomain = (url) => {
  try {
    const { hostname } = new URL(url);
    const parts = hostname.split(".");
    return parts.length <= 2
      ? hostname
      : parts.slice(parts.length - 2).join(".");
  } catch (error) {
    console.error("Error parsing URL:", error);
    return url;
  }
};

const getApiDomain = () => {
  const origin =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : "";
  const hostname =
    typeof window !== "undefined" && window.location.hostname
      ? window.location.hostname
      : "";

  if (hostname === "localhost" || origin.includes("localhost")) {
    return "https://api.gateway.lipad.io";
  }

  const regex = /^((sandbox|dev)\.)?checkout\.(.+)$/;
  const match = regex.exec(hostname);

  if (match) {
    const envPrefix = match[2] ? `${match[2]}.` : "";
    const domainPart = match[3]; // e.g., "lipad.io" or "kasapay.com"
    return `https://${envPrefix}api.gateway.${domainPart}`;
  }

  const baseDomain = getBaseDomain(origin);
  return `https://api.gateway.${baseDomain}`;
};

const domain = getApiDomain();

export const fetchWrapper = ({ endpoint, method, token, body }) => {
  return fetch(`${domain}/api/v1/checkout${endpoint}`, {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      // "Cache-Control": "no-cache, no-store, must-revalidate",
      // Pragma: "no-cache",
      // Expires: "0",
    },
    body: JSON.stringify(body),
  });
};

export const formatPhoneNumber = (phoneNumber, countryCode) => {
  return countryCode + phoneNumber;
};

export const encrypt = (payload) => {
  let jsonStringPayload = JSON.stringify(payload);
  let key = SHA256(process.env.REACT_APP_IV_KEY)
    .toString(enc.Hex)
    .substring(0, 16);
  let secret = SHA256(process.env.REACT_APP_CONSUMER_SECRET)
    .toString(enc.Hex)
    .substring(0, 32);
  const cipher = AES.encrypt(jsonStringPayload, secret, {
    iv: key,
    mode: mode.CFB,
  });
  let encryptedPayload = cipher.ciphertext.toString(enc.Base64);

  return {
    access_key: process.env.REACT_APP_ACCESS_KEY,
    payload: encryptedPayload,
  };
};
