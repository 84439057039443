/* eslint-disable react-hooks/exhaustive-deps */
import { Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Container from "../components/layout/container";
import BodySection from "../components/sections/bodySection";
import BottomSection from "../components/sections/bottomSection";
import MiddleSection from "../components/sections/middleSection";
import TopSection from "../components/sections/topSection";
import BankTransferPaymentOptionsTile from "../components/tiles/bankTransferPaymentOptionsTile";
import CardPaymentOptionsTile from "../components/tiles/cardPaymentOptionsTile";
import MobilePaymentOptionsTile from "../components/tiles/mobilePaymentOptionsTile";
import UnionPayPaymentOptionTile from "../components/tiles/unionPayPaymentOptionTile";
import payloadState from "../recoil/payloadState";
import uiState from "../recoil/uiState";
import { getCheckoutStatus } from "../services/checkout";
import { filteredPaymentMethods } from "../util/helpers";

export default function SelectPaymentMethodPage() {
  const navigate = useNavigate();

  const payload = useRecoilValue(payloadState);
  const setUiState = useSetRecoilState(uiState);

  const [loading, setLoading] = useState(true);

  const mobilePaymentOptions = filteredPaymentMethods({
    payload,
    type: "mobile_money",
  });

  const cardPaymentOptions = filteredPaymentMethods({
    payload,
    type: "card",
  });

  const bankPaymentOptions = filteredPaymentMethods({
    payload,
    type: "bank",
  });

  const unionPayPaymentOption = payload.client_data.payment_methods.find(
    (e) => e.payment_method_code === "UNION_PAY"
  );

  useEffect(() => {
    getCheckoutStatus({
      checkout_request_id: payload.checkout_request_id,
      currency_code: payload.merchant_site_data.currency.currency_code,
      country_code: payload.merchant_site_data.country.three_digit_iso,
      token: payload.access_token,
    }).then((response) => {
      setUiState((prevState) => ({
        ...prevState,
        overall_payment_status: response.overall_payment_status,
        running_amounts: response.running_amounts,
      }));
      if (response.overall_payment_status === 801)
        navigate("/payment-successful");
      setLoading(false);
    });
  }, []);

  if (loading)
    return (
      <div className="grid h-screen w-screen place-content-center">
        <Spinner color="green" size={"xl"} />
      </div>
    );

  return (
    <Container>
      <div>
        <TopSection />
        <MiddleSection />
        <BodySection>
          <div className="space-y-4">
            <h2 className="text-center text-lg font-medium">
              How would you like to pay?
            </h2>
            {mobilePaymentOptions.length > 0 && (
              <MobilePaymentOptionsTile options={mobilePaymentOptions} />
            )}
            {cardPaymentOptions.length > 0 && (
              <CardPaymentOptionsTile options={cardPaymentOptions} />
            )}
            {bankPaymentOptions.length > 0 && (
              <BankTransferPaymentOptionsTile options={bankPaymentOptions} />
            )}
            {unionPayPaymentOption && (
              <UnionPayPaymentOptionTile options={unionPayPaymentOption} />
            )}
          </div>
        </BodySection>
        <BottomSection />
      </div>
    </Container>
  );
}
